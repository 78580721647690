
:root {
  /*element{background-color: var(--custom-color);}*/
  --weDareColor:#ca2126;
  --bodyBg:#222222;;
  --custom-color: rgb(220,182,104,0.7);
  --goldColorOpac:rgb(220,182,104,0.7);
  --bgOpaDark:rgba(15, 29, 34, 0.7);
  --bgDark:#222f3e;
  --darkText:#262b33;
  --whiteText: #ffff;
  --specialFont:  'Waiting for the Sunrise', cursive;
  --bodyBgLight:#f1f2f6;
  --bodySecondColor:#fdcb6e;
  --bodyLightSecondColorOpac:rgb(142, 142, 142,.7);

}


/* Added by Marius*/
.WhiteText{color:#fff;}
.bottom-fixed{
  position: fixed;
  bottom: 0;
  background-color: var(--custom-color);
  z-index: 10000;
}
.mobOption{
  text-decoration: none !important;
}

#mainMenu{
  transition: 0.4s;
}

.dflex{
  display: flex;
}

.paddingLarge{
  padding: 15px 30px;
}


/* reset ************************************************************************************************************************************************/
html *{box-sizing:border-box;outline:none;}
html, body{height:100%;min-height:100%;}
body{overflow-x:hidden;margin:0;padding:0;font-size:14px;line-height:1.7em;color:#222222;background-color:var(--bodyBg);font-family: 'Montserrat', sans-serif;font-weight:400;}
ul, ol {margin:0;padding:0;list-style:none}
img, a{border:0}
a:hover{transition: all 0.3s linear;transform:translateY(1) scale(1);-webkit-transform: translateY(1) scale(1);}
img{width:100%;height:auto;float:left}
a, img{outline:none;}
p{padding-bottom:10px;margin:0;}
::-webkit-input-placeholder {font-family: 'Montserrat', sans-serif;font-weight:300;font-size:1em;text-transform:none;color:#929292;transition: all 0.3s linear;}
::-moz-placeholder {font-family: 'Montserrat', sans-serif;font-weight:300;font-size:1em;text-transform:none;color:#929292;transition: all 0.3s linear;}
:-ms-input-placeholder {font-family: 'Montserrat', sans-serif;font-weight:300;font-size:1em;text-transform:none;color:#929292;transition: all 0.3s linear;}
:-moz-placeholder {font-family: 'Montserrat', sans-serif;font-weight:300;font-size:1em;text-transform:none;color:#929292;transition: all 0.3s linear;}

label{vertical-align:middle}
input, select{height:22px;}
input, textarea, select, button{transition: all 0.3s linear;font-family: 'Montserrat', sans-serif;font-weight:400;font-size:14px;border-radius:4px;line-height:1;border:1px solid #AFAFAE;padding:0 8px;background-color:#fff;vertical-align:middle;color:#222222;height:45px;width:100%}
textarea{padding:15px 8px;height:200px}
input:focus, select:focus, textarea:focus{color:var(--custom-color);-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;outline:none;border-color:#000;}
::-webkit-input-placeholder{color:#AFAFAE;}
:-moz-placeholder{color:#AFAFAE;opacity:1;}
::-moz-placeholder{color:#AFAFAE;opacity:1;}
:-ms-input-placeholder{color:#AFAFAE;}
input:focus::-webkit-input-placeholder{opacity:1;color:var(--custom-color);}
input:focus:-moz-placeholder{opacity:1;color:var(--custom-color);}
input:focus::-moz-placeholder{opacity:1;color:var(--custom-color);}
input:focus:-ms-input-placeholder{opacity:1;color:var(--custom-color);}


/*Main*/
.top{position:absolute;top:0;left:0;z-index:9999999}
.top .superTop{display:flex;align-items:center;height:22px;position:relative;z-index:9999999;padding:0 40px;font-size:9.5px;letter-spacing: 1px;color:var(--darkText);font-weight:600}
.top .superTop ul{text-align:right;margin:0;padding:0}
.top .superTop ul li{display:inline-block;margin-left:25px;text-transform:uppercase;}
.top .superTop ul li a{color:var(--darkText);text-decoration:none}
.top .superTop .searchWrap span{display:inline-block;padding:0 30px;background-color:rgb(255, 255, 255,.1);cursor:pointer;}
.top .superTop .subDiv{top:22px;padding:0 40px;background:var(--bgOpaDark)}
.top .superTop .subDiv a{color:#fff;}
.top .mainMenu{transition: all 0.3s linear;position:fixed;top:22px;left:0;padding:0 20px 0 40px;display:flex;justify-content:space-between;align-items:center;background-color:var(--bgOpaDark)}
.top .mainMenu header{line-height:1}
.top .mainMenu header h1{margin:0;padding:0;display:inline-flex;}
.top .mainMenu .logoTop{display:inline-block;width:180px;}
.top .mainMenu ul{text-align:right;font-size:13px}
.top .mainMenu ul li{display:inline-block;color:#fff;float:left;}
.top .mainMenu ul li a{color:#fff;text-decoration:none;text-transform:uppercase;font-weight:400;padding:20px;display:block;}
.top .mainMenu ul li.sel a, .top .mainMenu ul li a:hover{background-color:var(--bgDark);}
.top .mainMenu.animateTop{top:0;}

.topSlider {background:var(--bodyBgLight);position:relative;}
.topSlider .slideEl .slideElWrapper{display:flex;align-items:center;justify-content:space-between;}
.topSlider .slideEl .slideElWrapper img{width:45%}
.topSlider .slideEl .slideElWrapper .textBox{padding-left:5%}
.topSlider .slideEl .slideElWrapper .textBox h1{font-size:2.8em;font-weight:200;margin:0;line-height:1em;color:var(--custom-color);}
.topSlider .slideEl .slideElWrapper .textBox h2{font-size:1.4em;font-weight:400;margin:10px 0 20px 0;line-height:1.2em}
.topSlider .slideEl .slideElWrapper .textBox a{background-color:var(--custom-color);text-decoration:none;margin-top:20px;text-transform:uppercase;color:#fff;padding:15px 30px;border-radius:4px;display:inline-block;line-height:1em}
.topSlider .slideEl.slideElBgImg img{width:100%;}
.topSlider .slideEl.slideElBgImg .textBox{position:absolute;left:5%}
.internalSlider .slideEl .slideElWrapper img{width:50%}
.internalSlider .slideEl .slideElWrapper .textBox{padding:0 5% 0 0;width:50%;}

.sectionBlock{padding-top:60px;padding-bottom:60px;}
.sectionBlock h1{font-size:2.8em;font-weight:200;margin:0;line-height:1em;color:var(--custom-color);}
.sectionBlock h2{font-size:1.4em;font-weight:400;margin:10px 0 20px 0;line-height:1.2em}
.sectionBlock.asideBlocks{display:flex;padding:0;align-items:center;}
.sectionBlock.asideBlocks > div{width:50%;padding:80px;}
.sectionBlock.asideBlocks div.bgDark{color:#fff;}
.sectionBlock h3{font-weight:300;font-size:1.3em;line-height:1.2em;margin:15px 0;}
.sectionBlock h3 span{font-weight:400;font-size:1.2em;display:block;}
.sectionBlock .blocksS{display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:center;}
.sectionBlock .blocksS div{width:250px;padding:50px 20px;}
.sectionBlock .blocksS span.lnr{display:inline-block;font-size:30px;border:1px solid var(--custom-color);padding:50px;border-radius:100px;color:var(--custom-color);}
.sectionBlock.blockContatti{background:var(--bgDark)}
.sectionBlock.blockContatti h2, .sectionBlock.blockContatti h1{color:#fff;font-weight:300}

.bgBlockParallax{color:#fff;height:500px;background:url("../img/eat/voi2.jpg");}
.bgBlockParallaxland{color:#fff;height:300px;background-image: url("https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"); opacity: 0.4;}

.blockOverflow{display:flex;width:100%;margin:auto;justify-content:flex-start;align-items:center;}
.blockOverflow .textBox{width:55%;padding:100px;border-radius:0 4px 4px 0;}
.blockOverflow img{width:30%;z-index:9999;border-radius:4px}
.bgAndRight, .bgSolo{background:var(--bodyBgLight);}
.bgAndRight .blockOverflow{justify-content:flex-end;}
.wedareeatHome .blockOverflow img, .wedareeatHome .blockOverflow .textBox{width:50%}

.priceBlock{padding:180px 0 0 0; background-color: var(--whiteText);}
.tabellaPrezziList{display:flex;flex-wrap:wrap;justify-content:center;  }
.tabellaPrezziList .tabellaPrezzi{border:1px solid #e8e8e8;padding:40px 20px;border-radius:10px;margin:20px;width:340px; background-color: var(--bodyBg);}
.tabellaPrezziList .tabellaPrezzi h1{font-weight:600;font-size:2em;color:var(--custom-color);;}
.tabellaPrezziList .tabellaPrezzi h1 span{font-size:0.7em;font-weight:300;}
.tabellaPrezziList .tabellaPrezzi h2{margin:0 0 10px 0;text-transform:uppercase;font-size:1.2em;font-weight:400; color: var(--custom-color);}
.tabellaPrezziList .tabellaPrezzi ul{text-align:left;margin-top:20px}
.tabellaPrezziList .tabellaPrezzi ul span.lnr{color:#1abc9c;position:absolute;left:0;top:2px;font-size:17px}
.tabellaPrezziList .tabellaPrezzi ul span.lnr.lnr-cross-circle{color:#e74c3c;}
.tabellaPrezziList .tabellaPrezzi ul li{margin:5px 0;position:relative;padding-left:23px; color:var(--whiteText)}
.tabellaPrezziList .notaLittle{margin-top:15px;line-height:1.2em}
.tabellaPrezziList .notaLittle span{font-size:.8em;}

.checkoutBox.visible{right:50px;}
.checkoutBox{transition:all .4s cubic-bezier(0.4, 0, 1, 1);position:fixed;top:0;right:-100%;height:100vh;overflow-y:auto;width:40%;min-width:340px;background:var(--bgDark);color:#fff;padding:70px 50px;z-index:999999999}
.checkoutBox .openCheck{position:absolute;right:20px;top:20px;font-size:30px;cursor:pointer;}
.checkoutBox .titoloBig{text-transform:uppercase;display:block;font-size:2.5em;font-weight:100;margin:0 0 10px 0;}
.checkoutBox .titoloServizio{display:block;text-transform:uppercase;letter-spacing:1px;font-size:1.5em;}
.checkoutBox .titoloServizio span{color:var(--custom-color);}
.checkoutBox .tabellaPrezziList .tabellaPrezzi{width:100%;padding:0;margin:0;border:0;}
.checkoutBox .tabellaPrezziList .tabellaPrezzi ul{column-count:2;column-gap:10px;font-size:12px;line-height:1.5em}
.checkoutBox .tabellaPrezziList .tabellaPrezzi ul .notaLittle{column-span: all;margin:20px 0 0 0;padding:0;font-size:13px;letter-spacing:0.01em}
.checkoutBox .tabellaPrezziList .tabellaPrezzi ul li{margin:0 0 10px 0;}
.checkoutBox .tabellaPrezziList .tabellaPrezzi ul li.noIn{display:none}
.checkoutBox .tabellaPrezziList .tabellaPrezzi ul span.lnr{font-size:14px;top:1px;}
.checkoutBox .totalCheck{font-weight:600;margin-top:40px;padding-top:10px;border-top:1px solid #0d404e;text-transform:uppercase;letter-spacing:0.02em}
.checkoutBox .totalCheck span.priceSpan{float:right;text-transform:none;color:var(--custom-color);}
.checkoutBox .payBtn{float:right;background:transparent;color:var(--custom-color);border-color:var(--custom-color);}
.checkoutBox form.visible{display:flex}
.checkoutBox form{display:none;flex-wrap:wrap;justify-content:space-between;}
.checkoutBox form input{width:48%;height:35px;background:var(--bgDark);border:none;border-bottom:1px solid #ddd;margin:7px 0;color:#ddd;border-radius:0}
.checkoutBox form button{padding:12px 30px;font-family:'Montserrat', sans-serif;font-size:13px;text-transform:uppercase;background:var(--custom-color);;color:#fff;margin:15px 0 0 auto;}
.checkoutBox p.switchLogin{text-align:center;cursor:pointer;display:block;margin:50px 0 0 0;text-transform:uppercase;font-size:12px;width:100%}
.checkoutBox p.switchLogin span{color:var(--custom-color);}

.slick-dots-custom{position:absolute;bottom:20px;left:0;width:100%;text-align:center;}
.slick-dots-custom li{border:1px solid var(--custom-color);display:inline-block;border-radius:20px;background:#fff;height:15px;width:15px;margin:0 3px;transition: all 0.3s linear;cursor:pointer;}
.slick-dots-custom li button{border:0;background:transparent;outline:none;font-size:0;line-height:0}
.slick-dots-custom li.slick-active{width:45px;background:var(--custom-color)}

.btn{line-height:1em;border:1px solid transparent;text-transform:none;cursor:pointer;font-weight:400;margin:15px 0;padding-left:30px;padding-right:30px;background: var(--custom-color);color:#fff;text-decoration:none;min-width:180px;border-radius:4px}
.btn:hover, .btn.btnOutline{-webkit-transition: background-color 0.3s linear;-ms-transition: background-color 0.3s linear;transition: background-color 0.3s linear;color: var(--custom-color);background:transparent;border:1px solid  var(--custom-color);}
a.btn, span.btn{text-decoration:none;display:inline-block;padding:12px 50px;}

.subDiv{visibility: hidden;opacity:0;position:absolute;top:39px;left:0;padding:10px;background:#ffffff;text-align:center;width:100%;transform: translateY(-2em); z-index: -1;transition:all 0.3s ease-in-out 0s, visibility 0s linear 0.3s}
.subDiv.opened{visibility: visible;opacity:1; z-index: 1;transform: translateY(0%); transition-delay: 0s, 0s, 0.3s;}
.subDiv ul {max-height:150px;overflow-y:auto;}
.subDiv ul li{position:relative;padding-left:25px;padding-top:1px;}
.subDiv ul li:before{content:"";height:17px;width:17px;border:1px solid #6a6a6a;position:absolute;left:0;top:0;}
.subDiv .filterMenu{padding:3px 0;}
.subDiv .filterMenu li{margin:10px 0;text-align:left;}
.subDiv .filterMenu li.sel:after{content:"";position:absolute;left:5px;top:5px;width:8px;height:8px;background:var(--custom-color)}


.mobileMenu{z-index:99999999;position:fixed;top:0;left:0;width:100%;align-items:center;display:none;justify-content:space-between;padding:10px 5%;background:var(--bgDark);}
.mobileMenu img{width:130px}
.mobileMenu .openMenuMob{font-size:30px;color:#fff;}
.menuLateraleMobile{text-align:center;position:fixed;left:-100%;top:0;width:90%;letter-spacing:1px;font-weight:200;height:100%;background:var(--bgDark); transition: all .3s linear;display:flex;flex-flow:column;justify-content:flex-start;z-index:99999999}
.menuOpened .menuLateraleMobile{left:0;color:#fff;}
.menuLateraleMobile img.logo{float:none;margin:60px auto 20px auto;width:150px}
.menuLateraleMobile .mainMenu{width:100%;float:left;margin:20px 0;}
.menuLateraleMobile .mainMenu li a,.menuLateraleMobile .mainMenu li .asA{text-decoration:none;min-height:45px;display:flex;align-items:center;justify-content:flex-start;padding:0 15px;cursor:pointer;border-bottom:1px solid rgb(115, 185, 255,.3);color:#fff;}
.menuLateraleMobile .mainMenu li.sel a{background:rgb(115, 185, 255,.3)}
.menuLateraleMobile .mainMenu li span.lnr{margin-right:10px}
.menuLateraleMobile .mainMenu.endMenu{justify-self:end;margin:auto auto 0 auto}
.menuLateraleMobile .mainMenu.endMenu li{border-top:1px solid var(--bgDark)}
.menuLateraleMobile .mainMenu.endMenu li.userciao{border:none;padding:15px 20px 15px 41px;text-align:left;font-weight:200;color:#fff;text-transform:uppercase;}
.menuLateraleMobile .openMenuMob{position:absolute;right:0;top:0;padding:10px;font-size:30px;}
.menuLateraleMobile .endMenu.cambiaLingua{padding:5px 15px;margin-top:auto;}
.menuLateraleMobile .endMenu.cambiaLingua li{display:inline-block;margin-right:10px;}
.menuLateraleMobile .endMenu.cambiaLingua li a{color:#fff;font-weight:400;text-decoration:none}
.menuLateraleMobile .endMenu.cambiaLingua li.block{display:block;}

.parallaxEffectCss{background-attachment:fixed;background-position: center;background-repeat: no-repeat;background-size: cover;}
.parallaxEffectCss.parallaxSlim{height:300px}

/*PRESET*/
code{color:#FC427B;background:#f2f2f2}
pre code{background-color:#fff;border: 1px solid #f2f2f2;display: block;padding: 20px;color:#6a6a6a;}
.displayNo{display:none;}
.disabled{opacity:.4;pointer-events:none;}
.blockEl{display:block;}
.inlineEl{display:inline!important;}
.row{width:100%;float:left;}
.rowMin{width:100%;padding-left:5%;padding-right:5%;float:left;}
.rowPaddingAround{padding-top:60px;padding-bottom:60px;padding-left:calc(5% + 15px);padding-right:calc(5% + 15px);width:100%;float:left;}
.bold, b{font-weight:700}
.alignCenter{text-align:center;}
.alignRight{text-align:right;}
.alignLeft{text-align:left;}
.marginTop{margin-top:20px}
.marginTopMin{margin-top:10px}
.noMargin{margin:0!important;}
.noPaddingUD{padding-top:0;padding-bottom:0}
.noPaddingD{padding-bottom:0}
.noPaddingU{padding-top:0}
.noBorder{border:0}
.noBg{background:transparent;}
.upperCase{text-transform:uppercase;}
.extraFont{font-size:1.8em;font-weight:700;line-height:1em}
.minFont{font-size:.9em;}
.midFont{font-size:1.2em;line-height:1em}
.evidenceFont{font-weight:400}
.imgGrayscale img{ -webkit-filter:grayscale(92%);filter: grayscale(92%);}
.imgOpacity img{opacity:.5}
.maxW{overflow-x:auto}
.maxH{overflow-y:auto;}
.bgDark{background:var(--bgDark);}
.bgBody{background:var(--bodyBg);}
.shadow{-webkit-box-shadow:0px 0px 7px 2px rgb(236, 236, 236);-moz-box-shadow:0px 0px 7px 2px rgb(236, 236, 236);box-shadow:0px 0px 7px 2px rgb(236, 236, 236)}
/*FINE PRESET*/

form.mainForm{width:100%;max-width:700px;margin:50px auto 0 auto;display:flex;flex-wrap:wrap;justify-content:space-between;}
form.mainForm .formGroup{width:48%;margin:0 0 20px 0;}
form.mainForm .formGroup.w100{width:100%}
form.mainForm label{display:inline-block;text-align:left;width:100%;margin:0 0 5px 0;}
form.mainForm .formGroup.littleInput{text-align:left;position:relative;}
form.mainForm .formGroup.littleInput input{width:20px;height:20px;display:inline-block;margin:0;position:absolute;left:0;top:0;}
form.mainForm .formGroup.littleInput label{display:inline-block;width:auto;margin:0 0 0 30px;}
form.mainForm .formGroup.littleInput span{width:100%;display:inline-block;margin-bottom:15px}
form.mainForm .formGroup.littleInput a{display:inline;font-weight:400;color:var(--custom-color);font-weight:600}

@media only screen and (max-width:1200px){ 

}
@media only screen and (max-width:815px){ /* smartphone orizzontale-verticale */
  .mobileMenu{display:flex}
  .top{display:none}
  .topSlider .slideEl .slideElWrapper,.internalSlider .slideEl .slideElWrapper {flex-wrap:wrap;flex-flow:column-reverse;text-align:center}
  .topSlider .slideEl .slideElWrapper img, .internalSlider .slideEl .slideElWrapper .textBox{width:100%}
  .topSlider .slideEl .slideElWrapper .textBox, .internalSlider .slideEl .slideElWrapper .textBox{padding:30px 5%;}
  .topSlider{padding:50px 0;}
  .topSlider.internalSlider{padding-bottom:0}
  .sectionBlock h1,.topSlider .slideEl .slideElWrapper .textBox h1{font-size:2.3em}
  .sectionBlock a.btn{margin-bottom:0}
  .blockOverflow{flex-wrap:wrap;text-align:center;}
  .blockOverflow img, .blockOverflow .textBox, .wedareeatHome .blockOverflow img, .wedareeatHome .blockOverflow .textBox{width:100%;padding:0 5%;}
  .bgAndRight .blockOverflow .textBox{padding-top:30px;}
  .wedareeatHome .bgBlockParallax{display:none}
  .sectionBlock .blocksS div{padding:20px;}
  .blockOverflow img{margin-top:65px}
  .bgAndRight .blockOverflow img{margin-top:0}
  .bgBlockParallax{height:auto}
  .priceBlock{padding-top:100px}
  .checkoutBox{transition:all .2s cubic-bezier(0.4, 0, 1, 1)}
  .checkoutBox.visible{right:0;width:100%;text-align:center;}
  .checkoutBox .tabellaPrezziList .tabellaPrezzi ul{column-count:auto}
  .checkoutBox .totalCheck span.priceSpan{display:block;float:none}
  .checkoutBox .payBtn{float:none}
  .checkoutBox form input{width:100%;padding:0 10px;border:1px solid #ddd;border-radius:4px}
  .checkoutBox form button{margin:20px auto 0 auto;}
  form.mainForm .formGroup{width:100%}
  .dflex{display: flex; flex-direction: column;}
}

.link-hover:hover{
  cursor: pointer;
  transition: 0.3s ease-out;
  background-color: rgba(220, 181, 104, 0.459);
}

